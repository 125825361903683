import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import InstagramFeed  from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'

import Layout from "../components/Layout";

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <section className="home-hero">
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0, transform: "translate(-50px)" }}
            animate={
              inView
                ? { opacity: 1, transform: "translate(0px)" }
                : { opacity: 1 }
            }
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
            className="ontopyo"
          >
            <h1
              data-scroll
              data-scroll-direction="horizontal"
              data-scroll-speed="2"
              data-scroll-position="top"
              data-scroll-delay="0.2"
            >
              PRECISION & EXCELLENCE IN THE SKIES
            </h1>
          </motion.div>
        )}
      </InView>

      <div className="scroll-line">
        <div className="center" />

        <div data-scroll data-scroll-speed="-1" className="marker" />
      </div>

      <div className="video-container">
        <div className="video-overlay" />
        <video loop autoPlay muted>
          <source src="../img/ex-hero.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
    <div className="about-heli">
      <div className="intro-heli">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
            >
              <p data-scroll data-scroll-speed="-1" data-scroll-delay="0.2">
                Excel Helicopters provide charter flights from any UK location.
                Whether business or pleasure, helicopter charter or private
                helicopter hire is the most time-efficient and memorable way to
                travel.
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.h1
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
            >
              <span data-scroll data-scroll-speed="1.5" data-scroll-delay="0.2">
                Excel
              </span>
              <span data-scroll data-scroll-speed="1" data-scroll-delay="0.4">
                Helicopter
              </span>
              <span data-scroll data-scroll-speed="0.5" data-scroll-delay="0.6">
                Charter
              </span>
            </motion.h1>
          )}
        </InView>
      </div>
    </div>
    <InView threshold={0.2}>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 1 }}
          transition={{
            duration: 0.25,
          }}
          className="images-heli"
        >
          <div data-scroll data-scroll-speed="3" className="img50">
            <img src="../img/heli-1.png" />
          </div>
          <div data-scroll data-scroll-speed="-1" className="img20">
            <img src="../img/heli-2.png" />
          </div>
          <div data-scroll data-scroll-speed="1" className="img20">
            <img src="../img/heli-3.png" />
          </div>
        </motion.div>
      )}
    </InView>
    <div className="further-heli shorten">
      <h1 className="logo-title">Who we work with</h1>
      <InView threshold={0.2}>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 1 }}
          transition={{
            duration: 0.25,
          }}
          className="logo-grid">
        <div className="logo-holder">
          <img src="../img/heli-bbc.png"/>
        </div>
        <div className="logo-holder">
          <img src="../img/heli-Sky.png"/>
        </div>
        <div className="logo-holder">
          <img src="../img/heli-F1.png"/>
        </div>
        <div className="logo-holder">
          <img src="../img/heli-Ss.png"/>
        </div>
        <div className="logo-holder">
          <img src="../img/heli-oast.png"/>
        </div>
        <div className="logo-holder">
          <img src="../img/heli-msv.png"/>
        </div>
        <div className="logo-holder">
          <a href="http://www.londonpleasureflights.co.uk/">
          <img src="../img/heli-London.png"/>
          </a>
        </div>
        <div className="logo-holder">
          <img src="../img/heli-IASTI.png"/>
        </div> 
      </motion.div>
      )}
    </InView>
    </div> 
    <div className="instagram-heli">
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : { opacity: 1 }}
            transition={{
              duration: 0.25,
              delay: 0.1,
            }}
            className="ig-block"
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="2.5"
            data-scroll-delay="0.2"
          >
          </motion.div>
        )}
      </InView>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            text
          }
          heading
          description
        }
      }
    }
  }
`;
